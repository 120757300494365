import Vue from "vue"
import Products from '@/components/widgets/products'
import Customers from '@/components/widgets/customers'
import Loading from '@/components/widgets/Loading.vue'
import SortedTablePlugin from "vue-sorted-table"
import DatePicker from 'vue2-datepicker'
import moment from "moment"
const _ = require('lodash')

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default {
  components: {
    Loading,
    DatePicker,
    Products,
    Customers
  },
  data() {
    return {
      loading: false, 
      customerOpend: false,
      productOpened: false,
      title: 'สร้างใบจอง',
      form: {
        license_plate: '',
        car_number: '',
        document_no: '',
        date: new Date(),
        type: 1,
        until: null,
        payment_terms: 1,
        note: '',
        more_weight: "",
        delivery: 1,
        customer_id: null
      },
      fields: [
        {
          key: "lot_no",
          label: "LOT No.",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "brand",
          label: "ยี่ห้อ.",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "invoice_date",
          label: "Invoice date",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "coil",
          label: "COIL NO.",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "size",
          label: "SIZE",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "color",
          label: "COLOR",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "net_weight",
          label: "NET WEIGHT",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 text-right font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "kg_m",
          label: "Kg/m",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 text-right font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "length",
          label: "LENGTH",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 text-right font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "status",
          label: "สถานะ",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "can_pick",
          label: "สาขาสั่งได้",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "warehouse",
          label: "คลัง",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "row",
          label: "แถว",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "price",
          label: "ราคาต่อกิโล/Kg",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
        },
        {
          key: "note",
          label: "หมายเหตุ",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
        },
        {
          key: "action",
          label: "#",
          thClass: "text-xs-1/2 font-weight-bold",
          class: "text-right text-xs-1/2",
        }
      ],
      customerfields: [
        { 
          key: 'name', 
          label: 'ลูกค้า', 
          sortable: true, 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'td-top text-xs-1/2'
        },
        { 
          key: 'address', 
          label: 'ที่อยู่', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2'
        },
        { 
          key: 'contact', 
          label: 'ติดต่อ', 
          sortable: true, 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-left text-gray-400 td-top text-xs-1/2'
        },
        { 
          key: 'action', 
          label: '#', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
        }
      ],
      orderStatus: [
        { text: "รอเข้า", value: "awaiting" },
        { text: "พร้อมสั่ง", value: "readyfororder" },
        { text: "กำลังส่ง", value: "transferring" },
        { text: "รับแล้ว", value: "delivered" },
      ],
      customer: [],
      products: [],
      currentMode: false
    }
  },
  methods: {
    previous() {
      this.$router.push('/order-centric')
    },
    onselectProduct() {
      this.productOpened = !this.productOpened
    },
    firstOrupdate(items) {
      if(this.products.length === 0) {
        this.products = items
      } else {
        for (let index = 0; index < items.length; index++) {
          const element = items[index]
          const item = _.find(this.products, { uuid: element.uuid })
          if (!item) {
            this.products.push(element)
          }
        }
      }
    },
    onremoveProduct(id) {
      const index = _.findIndex(this.products, function(o) { return o.uuid == id })
      this.products.splice(index, 1)
    },
    onInitProducts(event) {
      this.productOpened = false
      if (event.status) {
        this.firstOrupdate(event.data)
      }
    },
    onselectCustomer() {
      this.customerOpend = !this.customerOpend
    },
    onremove(id) {
      const index = _.findIndex(this.customer, function(o) { return o.uuid == id })
      this.customer.splice(index, 1)
    },
    onInitCustomer(event) {
      this.customerOpend = false
      if (event.status) {
        let items = event.data
        if(this.customer.length === 0) {
          this.customer.push(items)
        } else {
          const item = _.find(this.customer, { uuid: items.uuid })
          if (!item) {
            this.customer.push(items)
          }
        }
      }
    },
    async onSubmitForm() {
      
      if(this.customer.length === 0){
        this.onExceptionHandler("กรุณาเลือกลูกค้า")
        return
      }

      if(this.products.length === 0){
        this.onExceptionHandler("กรุณาเลือกม้วน")
        return
      }

      if(this.form.date === null){
        this.loading = false  
        this.onExceptionHandler("กรุณาเลือกวันที่")
        return
      }

      this.loading = true
      const formData = new FormData()
      formData.append('document_no', this.form.document_no)
      formData.append('date', moment(this.form.date))
      formData.append('type', this.form.type)
      formData.append('until', this.form.until != null ? moment(this.form.until) : null)
      formData.append('payment_terms', this.form.payment_terms)
      formData.append('note', this.form.note)
      formData.append('license_plate', this.form.license_plate)
      formData.append('car_number', this.form.car_number)
      formData.append('delivery', this.form.delivery)
      
      this.form.customer_id = this.customer[0].id
      formData.append('customer_id', this.form.customer_id)
      formData.append('more_weight', this.form.more_weight)
      formData.append('customer', JSON.stringify(this.customer))
      formData.append('products', JSON.stringify(this.products))

      try {
        if (this.$route.params.id) {
          await Vue.prototype.$axios.post(
            `${Vue.prototype.$host}/api/v1/document/preemption-customer/edit/${this.$route.params.id}`,
            formData
          );
        } else {
          await Vue.prototype.$axios.post(`${Vue.prototype.$host}/api/v1/document/preemption-customer`, formData)
        }

        this.customer = []
        this.products = []
        this.loading = false
        this.$swal({
          width: "29rem",
          padding: '1.825em',
          html: '<div class="d-flex flex-row align-items-center">' +
            '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>'+
            '<div class="d-flex flex-column">'+
            '<span class="font-bold text-base py-2.5 text-left">แจ้งเตือน</span>'+
            `<span class="text-base text-left text-gray-500">บันทึกข้อมูลเรียบร้อยแล้ว</span>`+
            '</div>'+
          '</div>',
            allowOutsideClick: false,
            focusConfirm: false,
            customClass: {
              popup: 'swal2-custom-rounded',
              closeButton: 'text-3xl swal2-custom-close',
              actions: 'justify-content-end',
              contant: 'd-flex flex-row justify-content-around px-2',
              title: 'd-none',
              confirmButton: "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
              cancelButton: 'btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal'
            },
            showCancelButton: false,
            confirmButtonText: "ตกลง",
            cancelButtonText: "ยกเลิก",
            showCloseButton: true,
        }).then(result => {
          this.previous()
        })
 
      } catch (error) {
        this.loading = false
        this.onExceptionHandler(error.response.data.message)
      }
    }
  },
  async created() {
    try {
      const id = this.$route.params.id;
      this.currentMode = this.$route.params.id? true: false
      if (id) {
        this.title = 'แก้ไขใบจอง'
        this.loading = true
        const res = await this.$axios.get(
          this.$host + "/api/v1/document/preemption/" + id,
          {
            headers: {},
          }
        )

        this.loading = false
        const data = res.data
        const products = []
        for (let index = 0; index < data.products.length; index++) {
          const element = data.products[index];
          products.push(element.detail)
        }

        const customer = []
        for (let index = 0; index < data.customer.length; index++) {
          const element = data.customer[index];
          customer.push(element.detail)
        }

        const preemption = data.data;
        this.document_id = data.id;

        this.form.document_no = "PO-" + preemption.document_no;
        this.form.date = new Date(preemption.date);
        this.form.type = preemption.type;
        this.form.payment_terms = preemption.payment_terms;
        this.form.until = preemption.until ? new Date(preemption.until) : null;
        this.form.car_number = preemption.car_number;
        this.form.license_plate = preemption.license_plate;
        this.form.more_weight = preemption.more_weight;
        this.form.note = preemption.note;
        this.form.delivery = preemption.delivery
        this.customer = customer
        this.products = products;
      }
    } catch (error) {
      this.loading = false
      this.onExceptionHandler(error)
    }
  }
}